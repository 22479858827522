// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserBareMetalProject_Details_RequiredProductsScss from "./UserBareMetalProject_Details_RequiredProducts.scss";
import * as UserBareMetalProject_Details_RequiredProducts_ProductServiceDescription from "./UserBareMetalProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserBareMetalProject_Details_RequiredProductsScss;

function panelHeading(panelHeading$1) {
  return JsxRuntime.jsx("h4", {
              children: panelHeading$1,
              className: css.panelHeading
            });
}

function UserBareMetalProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions(props) {
  var options = props.options;
  if (options.length !== 0) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                      options: options,
                      cssStart: "StayShaded"
                    }),
                className: css.propertiesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptions = {
  make: UserBareMetalProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions
};

function UserBareMetalProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var bareMetalConfiguration = props.bareMetalConfiguration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: props.heading,
              isExpanded: isExpanded,
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("p", {
                                          children: "Overview",
                                          className: css.productName
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(UserBareMetalProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                        label: "Estimated Servers",
                                                        value: String(bareMetalConfiguration.estimatedServers)
                                                      }),
                                                  className: Cx.cx([
                                                        css.propertyLeft,
                                                        css.stayShaded
                                                      ])
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(UserBareMetalProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                        label: "Est. Monthly Budget",
                                                        value: bareMetalConfiguration.estimatedBudget
                                                      }),
                                                  className: Cx.cx([
                                                        css.propertyRight,
                                                        css.switchShaded
                                                      ])
                                                })
                                          ],
                                          className: css.propertiesContainer
                                        })
                                  ],
                                  className: css.propertiesContainer
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("p", {
                                          children: "Requested Regions",
                                          className: css.productName
                                        }),
                                    JsxRuntime.jsx(UserBareMetalProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions, {
                                          options: bareMetalConfiguration.regions
                                        })
                                  ],
                                  className: css.propertiesContainer
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("p", {
                                          children: "Operating Systems",
                                          className: css.productName
                                        }),
                                    JsxRuntime.jsx(UserBareMetalProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions, {
                                          options: bareMetalConfiguration.operatingSystems
                                        })
                                  ],
                                  className: css.propertiesContainer
                                })
                          ]
                        }),
                    className: css.panelBody
                  })
            });
}

var make = UserBareMetalProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptions ,
  make ,
}
/* css Not a pure module */
