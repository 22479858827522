// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Project from "./Project.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as UserContact from "./UserContact.res.js";
import * as ProviderForProject from "./ProviderForProject.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as BareMetalConfiguration from "./BareMetalConfiguration.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", Project.Id.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              status: field.required("status", Project.Status.decoder),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              totalProposals: field.required("totalProposals", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              isAgent: field.required("isAgent", Json_Decode$JsonCombinators.bool),
              businessName: field.required("businessName", Json_Decode$JsonCombinators.string),
              businessContactName: field.required("businessContactName", Json_Decode$JsonCombinators.string),
              headquartersAddress: field.required("headquartersAddress", Json_Decode$JsonCombinators.string),
              businessEmail: field.required("businessEmail", Json_Decode$JsonCombinators.string),
              businessPhone: field.required("businessPhone", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              owner: field.required("owner", Project.Owner.decoder),
              contacts: field.required("contacts", Json_Decode$JsonCombinators.array(UserContact.decoder)),
              documents: field.required("documents", Json_Decode$JsonCombinators.array(Project.$$Document.decoder)),
              bareMetalConfigurations: field.required("bareMetalConfigurations", Json_Decode$JsonCombinators.array(BareMetalConfiguration.decoder)),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(ProviderForProject.decoder))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
