// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              startDate: field.required("startDate", $$Date.Naive.decoder),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.$$int),
              estimatedServers: field.required("estimatedServers", Json_Decode$JsonCombinators.$$int),
              estimatedBudget: field.required("estimatedBudget", Json_Decode$JsonCombinators.string),
              regions: field.required("regions", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              operatingSystems: field.required("operatingSystems", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
